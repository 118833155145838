import React from "react"
import {
  ContactOptionsContainer,
  ContactOptionsContentContainer,
} from "./components"
import SectionTitleDescriptionCta from "../section/section-title-description-cta"
import SubmitForm from "./submit-form"

const SubmitFormContainer = props => {
  const message =
    "Interested in our product ? Write us a message. We always reply within 24 hours."

  const messageType = props.state ? (
    <>
      {message}
      {props.state.packageType && (
        <p style={{ marginTop: 7, color: "red" }}>
          You have selected plan: {props.state.packageType}
        </p>
      )}
    </>
  ) : (
    <>{message}</>
  )

  return (
    <ContactOptionsContainer>
      <ContactOptionsContentContainer>
        <SectionTitleDescriptionCta
          title="Contact Sales"
          description={messageType}
        />
        <SubmitForm
          type="sales"
          plan={props.state ? props.state.packageType : ""}
        />
      </ContactOptionsContentContainer>
    </ContactOptionsContainer>
  )
}

export default SubmitFormContainer
